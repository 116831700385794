/* You can add global styles to this file, and also import other style files */
@import "../node_modules/tippy.js/dist/tippy.css";
.alert {
  font-size: 0.8em;
  margin-bottom: 0.5em;
}

.alert-danger {
  color: #cb4335;
}

.po-table-header-flex-right {
  justify-content: flex-start;
}

.po-table .po-table-column-right {
  text-align: start;
}

.text-weekend-or-holiday {
  color: var(--color-07);
}

.required-field .po-label::after {
  content: " *";
  color: #cb4335;
}

.po-accordion-item-header-button:focus, .po-accordion-item-header-button:focus::before {
  border: none;
  outline: none;
}

.po-row.po-table-footer-show-more.ng-star-inserted {
  display: none !important;
} 

.po-modal-body .po-table-footer-show-more.ng-star-inserted {
  display: flex !important;
}

/* table {
  border-width: 1px;
  border-color: lightgray !important;
  border-style: solid !important;
  border-radius: 5px !important;
} */
